// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap';
// import 'bootstrap-icons/font/bootstrap-icons.css'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require('jquery')
require('cookieconsent')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
    var flash = $('#flash-container');
    if (flash.length > 0) {
        window.setTimeout(function () {
            flash.fadeTo(1000, 0).slideUp(1000, function () {
                $(this).remove();
            });
        }, 2000);
    }

//Remove shopping cart button from navbar on speedorder path
    if ($(".orders.speedorder").length > 0) {
        $('#shoppingCartButton').remove();
    }

//Initialize cookie consent
    window.cookieconsent.initialise({
        container: document.getElementById("cookieconsent"),
        palette:{
            popup: {background: "#4d6c50"},
            button: {background: "#6c757d;"},
        },
        revokable: false,
        onStatusChange: function(status) {
            console.log(this.hasConsented() ?
                'enable cookies' : 'disable cookies');
        },
        "position": "bottom-left",
        "theme": "classic",
        "domain": "https://urmark.se",
        "secure": true,
        "content": {
            "header": 'Denna webbplats använder Cookies!',
            "message": 'Urmark använder cookies för att ge dig en bättre upplevelse på vår ' +
                'webbplats. Genom att acceptera bekräftar du att du samtycker till vår användning av Cookies.',
            "dismiss": 'Jag förstår',
            "allow": 'Tillåt cookies',
            "deny": 'Neka Cookies',
            "link": 'Integritetspolicy',
            "href": 'https://urmark.se/pages/privacy_policy',
            "close": '&#x274c;',
            "policy": 'Cookies',
            "target": '_blank',
        }
    });
})
